var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.course)?_c('BaseLayout',{staticClass:"px-0",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-icon',{attrs:{"left":""},on:{"click":_vm.navigateToCourseDashboard}},[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$route.params.id)+" - "+_vm._s(_vm.course.courseName)+" ")]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"disabled":_vm.isCourseDone,"color":"white","outlined":"","data-cy":"open-new-message-popup"},on:{"click":_vm.openNewInvitations}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Nye invitasjoner ")],1)]},proxy:true}],null,false,282766271)},[_c('BaseTableSimple',{attrs:{"headers":_vm.headers,"items":_vm.pendingInvitations,"items-per-page":50,"scrollable":false},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title font-weight-light text-center align-self"},[_vm._v("Åpne invitasjoner")])]},proxy:true},{key:"item.status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getCourseInvitationStatus(value))+" ")]}},{key:"item.inserted",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(value))+" ")]}},(!_vm.isCourseDone)?{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.deleteInvitation(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-trash-can ")])]}}],null,true)},[_c('span',[_vm._v("Slett invitasjon")])])]}}:null],null,true)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }